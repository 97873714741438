(<template>
  <article class="magic-link">
    <tt-progress />
  </article>
</template>)

<script>
  export default {
    computed: {clientWidth() { return this.$store.state.GlobalStore.clientWidth; }},
    watch: {
      clientWidth() {
        if (this.$isOnDomain('tikktalk')
          && this.clientWidth
          && this.clientWidth < 768
          && !this.$store.state.mobileApp) {
          const res = `https://my.salita.no/${window.location.pathname.slice(1)}${window.location.search}`;
          window.location.href = res;
        }
      }
    },
    mounted() {
      setTimeout(() => {
        const token = this.$route.params.token;
        if (token) {
          this.$store.dispatch('verifyMagicLink', token).then((res) => {
            this.$store.dispatch('TTAuthStore/logOut').then(() => {
              this.$store.commit('setMobileAppRedirectedLink', this.$route.fullPath);
              this.$store.dispatch('TTAuthStore/logIn', {token: res.token, uid: res.uid});
              this.$store.commit('UserInfoStore/setUserInfo', {userInfoObj: {uid: res.uid}});
              this.$store.commit('TTAuthStore/setAuthInfoToStorage', {token: res.token, uid: res.uid});
              this.$store.commit('TTAuthStore/setGodMode', res.godmode);
              if (res.godmode) this.$store.dispatch('FeatureFlagsStore/fetchFeatureFlags');
              this.$store.dispatch('UserInfoStore/fetchUserInfo', res.uid).then(() => {
                const prefix = this.$store.state.UserInfoStore.mainUserInfo.role == 'server' ? 'Server' : 'Buyer';
                const iframeSrc = this.$router.resolve(
                  this.$makeRoute({name: 'SystemLog', params: {id: res.action_id}, query: {page: 1, isOnTranslation: true}})
                ).href;
                switch (res.action) {
                  case 'show_job':
                    this.$router.replace({
                      name: `${prefix}OneAssignment`,
                      params: {
                        id: res.action_id
                      }
                    });
                    break;
                  case 'book_service_assignment':
                    this.$router.replace({
                      name: 'BuyerOtherServiceAssignment',
                      params: {
                        service: 1
                      }
                    });
                    break;
                  case 'request_withdraw_from_job':
                    this.$router.replace({
                      name: `${prefix}OneAssignment`,
                      params: {
                        id: res.action_id
                      },
                      query: {
                        action: 'withdraw'
                      }
                    });
                    break;
                  case 'request_cancel_job':
                    this.$router.replace({
                      name: `${prefix}OneAssignment`,
                      params: {
                        id: res.action_id
                      },
                      query: {
                        action: 'cancel'
                      }
                    });
                    break;
                  case 'post_assignment':
                    this.$router.replace({
                      name: 'BuyerPostInterpretation'
                    });
                    break;
                  case 'manage_employees':
                    this.$router.replace({
                      name: 'BuyerEmployees'
                    });
                    break;
                  case 'manage_departments':
                    this.$router.replace({
                      name: 'BuyerDepartments'
                    });
                    break;
                  case 'show_order':
                    this.$router.replace({
                      name: `${prefix}OneProject`,
                      params: {
                        id: res.action_id
                      }
                    });
                    break;
                  case 'show_order_system_log':
                    this.$router.replace({
                      name: 'BuyerOneProject',
                      params: {
                        id: res.action_id
                      }
                    });
                    this.$store.commit('ModalStore/setModal', {
                      component: 'system-log-modal',
                      classes: ['sk-modal--new', 'system-log-modal'],
                      width: 660,
                      data: {
                        title: this.$gettext('System log'),
                        data: {
                          iframeSrc
                        }
                      }
                    });
                    break;
                  default:
                    this.$router.replace({name: 'SignIn'});
                    break;
                }
              }).catch(() => {
                this.$router.replace({name: 'SignIn'});
              });
            });
          }).catch(() => {
            this.$router.replace({name: 'SignIn'});
          });
        } else {
          this.$router.replace({name: 'SignIn'});
        }
      });
    },
    destroyed() {
      if (this.$store.state.mobileApp) {
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    }
  };
</script>

<style scoped>
  .magic-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
</style>
